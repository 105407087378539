import { Model } from 'src/models/model'
import { ModelList } from 'src/models/modelList'
import type { ID, Nullable } from 'src/types'
import type { Opaque } from 'type-fest'

export type BlogGroupId = Opaque<string, 'BlogGroupId'>

export type BlogGroupSlug = Opaque<string, 'BlogGroupSlug'>

export interface BlogGroupData {
  id: ID
  slug: BlogGroupSlug
  icon: Nullable<string>
  name: Nullable<string>
  priority: Nullable<number>
  showOnMainPage: Nullable<boolean>
  backgroundColor: Nullable<string>
}

export type BlogGroupListData = BlogGroupData[]

export class BlogGroup extends Model<BlogGroupData> {
  constructor(data: BlogGroupData) {
    super(data)
  }

  get id(): BlogGroupId {
    return String(this.data.id) as BlogGroupId
  }

  get slug() {
    return this.data.slug
  }

  get icon() {
    return this.data.icon ?? undefined
  }

  get name() {
    return this.data.name ?? undefined
  }

  get color() {
    return this.data.backgroundColor ?? undefined
  }

  get priority() {
    return this.data.priority ?? 0
  }
}

export class BlogGroupList extends ModelList<typeof BlogGroup> {
  constructor(items?: BlogGroupListData) {
    super(BlogGroup, items)
  }

  get items() {
    return [...super.items].sort((a, b) => a.priority - b.priority)
  }
}

