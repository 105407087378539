<template>
  <div class="blog-post-badges">
    <app-label
      v-for="item in list.items"
      :key="item.id"
      :color="item.color"
      :to="RouterService.getRouteLocation('blog.group', { locale, slug: item.slug })"
      class="blog-post-badges__item"
    >
      {{ item.name }}
    </app-label>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'src/composables/useI18n'
import type { BlogGroupList } from 'src/models/blog/group'
import RouterService from 'src/services/router.service'

defineProps<{
  list: BlogGroupList
}>()

const { locale } = useI18n()
</script>

<style lang="scss" scoped>
.blog-post-badges {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sm);
}
</style>
